import PropTypes from "prop-types";
import React, { useState } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { mediaquery, rem, scaleSpacing } from "../../../../styles/theme";
import Button from "../../Atoms/Button";
import Image from "../../Atoms/Image";
import RichText from "../../Atoms/RichText";
import Svg from "../../Atoms/Svg";
import { Oswald, Slabo, SlaboSubtitle } from "../../Atoms/Typography";
import Popin from "../Popin";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${mediaquery.md(css`
    flex-direction: row;
    align-items: center;
  `)};
`;

const TestimonialWrapper = styled.div`
  flex: 1;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${({ isPopin }) => css`
    ${mediaquery.md(css`
      margin-left: ${isPopin ? "unset" : scaleSpacing(24)};
    `)};
  `};
`;

const ImageContainer = styled(Image)`
  width: ${scaleSpacing(24)};
  height: ${scaleSpacing(24)};
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  /* mask-image prevents an issue with safari : https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b */
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  mask-image: -webkit-radial-gradient(white, black);

  img {
    display: block;
    width: 100%;
  }
`;

const LogoContainer = styled.div`
  width: ${scaleSpacing(10)};
  height: ${scaleSpacing(10)};
  margin-left: ${scaleSpacing(10)};

  img {
    display: block;
    width: 100%;
  }
`;

const TextContainer = styled.div`
  margin-left: ${scaleSpacing(4)};
`;

const Title = styled.p`
  ${Oswald};
  font-size: ${rem(12)};
  line-height: ${rem(14)};
  letter-spacing: 0.2px;
  margin-bottom: ${scaleSpacing(2)};

  ${({ isPopin }) => css`
    ${mediaquery.md(css`
      display: ${isPopin ? "block" : "none"};
    `)};
  `};

  ${({ desktopOnly }) =>
    desktopOnly &&
    css`
      display: none;

      ${mediaquery.md(css`
        display: block;
        font-size: ${rem(24)};
        line-height: ${rem(30)};
        letter-spacing: -0.001em;
        margin-bottom: ${scaleSpacing(6)};
        margin-left: ${scaleSpacing(24)};
      `)};
    `};
`;

const Name = styled.p`
  margin-top: ${scaleSpacing(2)};
  ${Slabo};
  font-size: ${rem(14)};
  line-height: ${rem(18)};
  letter-spacing: 0.2px;
`;

const Description = styled.p`
  ${Slabo};
  font-size: ${rem(14)};
  line-height: ${rem(18)};
  letter-spacing: 0.2px;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${scaleSpacing(6)};
`;

const Content = styled.div`
  ${SlaboSubtitle};
  display: flex;
  flex-direction: row;
  ${mediaquery.md(css`
    margin-left: ${scaleSpacing(24)};
    margin-left: 0;
  `)};
`;

const StyledButton = styled(Button)`
  margin-top: ${scaleSpacing(6)};
  cursor: pointer;
  ${mediaquery.md(css`
    margin-left: ${scaleSpacing(24)};
  `)};
`;
const LeftQuote = styled.div`
  display: none;
  ${mediaquery.md(css`
    display: unset;
  `)};
`;
const UpperQuote = styled.div`
  display: unset;
  ${mediaquery.md(css`
    display: none;
  `)} margin: ${scaleSpacing(6)} 0;
`;

const SharedComponent = ({
  picture,
  logo,
  title,
  name,
  description,
  content,
  show,
  isPopin = false,
  displayQuote,
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      {displayQuote && (
        <UpperQuote>
          <Svg
            name="quote"
            size={96}
            height={48}
            css={css`
              top: ${scaleSpacing(2)};
            `}
          />
        </UpperQuote>
      )}
      <TestimonialWrapper>
        {title && !isPopin && <Title desktopOnly>{title}</Title>}
        <TopContainer isPopin={isPopin}>
          {picture && <ImageContainer source={picture} sizes="96px" isFixed />}
          <TextContainer>
            {title && <Title isPopin={isPopin}>{title}</Title>}
            {name && <Name>{name}</Name>}
            {description && <Description>{description}</Description>}
          </TextContainer>
          {logo && (
            <LogoContainer>
              <Image source={logo} sizes="40px" isFixed />
            </LogoContainer>
          )}
        </TopContainer>
        <BottomContainer>
          {content && (
            <Content>
              {displayQuote && (
                <LeftQuote>
                  <Svg
                    name="quote"
                    size={96}
                    height={48}
                    css={css`
                      top: ${scaleSpacing(2)};
                    `}
                  />
                </LeftQuote>
              )}
              <RichText data={content} />
            </Content>
          )}
          {!isPopin && (
            <StyledButton buttonTheme="primaryGrey" onClick={show}>
              {t("institut_testimonial_furtherreading")}
            </StyledButton>
          )}
        </BottomContainer>
      </TestimonialWrapper>
    </Wrapper>
  );
};

SharedComponent.propTypes = {
  picture: PropTypes.object.isRequired,
  logo: PropTypes.object,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  content: PropTypes.array.isRequired,
  show: PropTypes.func.isRequired,
  isPopin: PropTypes.bool,
  displayQuote: PropTypes.bool,
};

const Testimonial = ({
  picture,
  logo,
  title,
  name,
  description,
  shortTestimonial,
  longTestimonial,
}) => {
  const [showLongVersion, setShowLongVersion] = useState(false);
  const show = () => {
    setShowLongVersion(true);
  };

  const hide = () => {
    setShowLongVersion(false);
  };

  return (
    <div>
      <SharedComponent
        picture={picture}
        logo={logo}
        title={title}
        name={name}
        description={description}
        content={shortTestimonial}
        show={show}
        displayQuote
      />
      <Popin shown={showLongVersion} hide={hide}>
        <SharedComponent
          isPopin
          picture={picture}
          logo={logo}
          title={title}
          name={name}
          description={description}
          content={longTestimonial}
          show={show}
          displayQuote={false}
        />
      </Popin>
    </div>
  );
};

Testimonial.propTypes = {
  picture: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  shortTestimonial: PropTypes.array.isRequired,
  longTestimonial: PropTypes.array.isRequired,
  logo: PropTypes.object,
};

export default React.memo(Testimonial);
